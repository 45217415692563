import { signOut, getUserData } from './auth.js';

export async function loadSideNav(currentPage, session, subscription, userData) {
  const userSection = document.getElementById('userSection');
  const skeletonLoader = document.getElementById('skeletonLoader');

  if (skeletonLoader) {
    skeletonLoader.classList.remove('hidden');
  }

  setupLogoClick(session, subscription);
  setupPlatformLinks();

  let localUserData = userData;
  if (!localUserData && session && session.user) {
    try {
      localUserData = await getUserData(session.user.id);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  }

  const currentPlatform = getPlatformFromUrl() || currentPage;
  currentPage = currentPlatform;

  updateNavLinks(currentPage, (page) => page === currentPage);

  const submitLink = document.getElementById('link-submit');
  if (submitLink) {
    if (!session) {
      submitLink.setAttribute('href', '/new-user');
    } else {
      submitLink.setAttribute('href', '/submit');
    }
  }

  if (userSection) {
    if (session && localUserData) {
      if (skeletonLoader) skeletonLoader.classList.add('hidden');

      let userProfileImage = document.getElementById('userProfileImage');
      let userNameElement = document.getElementById('userName');

      if (!userProfileImage || !userNameElement) {
        userSection.innerHTML = `
          <div id="userProfile" class="flex items-center justify-between cursor-pointer p-2 rounded-md hover:bg-neutral-200">
            <div class="flex items-center space-x-2">
              <img id="userProfileImage" src="${localUserData?.profile_picture_url || '/path/to/default/image.png'}" loading="lazy" class="w-8 h-8 mr-0.5 rounded-md" alt="profile image">
              <div class="flex flex-col">
                <span id="userName" class="text-neutral-900 text-sm">${localUserData?.name || 'User'}</span>
                <span class="text-xs text-neutral-600">${subscription && subscription.is_current ? 'Subscribed' : 'Free plan'}</span>
              </div>
            </div>
            <img src="https://api.overshooot.com/storage/v1/object/public/assets/icons/arrow-down-ss-line.svg" class="h-4 w-4" alt="dropdown icon">
          </div>
          <div id="profileDropdown" class="absolute right-4 left-4 mt-2 font-medium bg-white rounded-md shadow-sm border border-neutral-200 p-1.5 z-50 hidden">
            <div id="userEmail" class="p-2 text-sm text-neutral-500 mb-1">${localUserData?.email || ''}</div>
            <a href="/" id="logoutButton" class="block w-full p-2 text-sm text-neutral-900 hover:bg-neutral-100 rounded-md">Logout</a>
          </div>
        `;
        setupEventListeners();
      } else {
        userProfileImage.src = localUserData?.profile_picture_url || '/path/to/default/image.png';
        userNameElement.textContent = localUserData?.name || 'User';
      }
    } else {
      if (skeletonLoader) {
        skeletonLoader.classList.add('hidden');
      }
      userSection.innerHTML = `
        <a id="loginLink" href="/new-session" class="block w-full text-center text-sm font-medium py-3 px-2 bg-neutral-900 text-white rounded-md hover:bg-neutral-800 transition-colors duration-200">
          Log in
        </a>
      `;
    }
  }

  // Replace the "Get Pro" section with the new "Pricing" button
  setupPricingButton(session, subscription);

  // Add event listener for logout
  const logoutButton = document.getElementById('logoutButton');
  if (logoutButton) {
    logoutButton.addEventListener('click', async (e) => {
      e.preventDefault();
      await signOut();
      window.location.href = '/';
    });
  }

  setupEventListeners();
}

function setupPricingButton(session, subscription) {
  const sidebarPromo = document.getElementById('sidebarPromo');

  if (sidebarPromo) {
    if (session && subscription && subscription.is_current) {
      sidebarPromo.style.display = 'none';
    } else {
      sidebarPromo.style.display = 'block';

      // Remove any existing event listener before adding a new one
      sidebarPromo.removeEventListener('click', handlePricingClick);

      // Define the click event function
      function handlePricingClick(e) {
        e.preventDefault();
        window.location.href = '/pricing';
      }

      // Add the event listener
      sidebarPromo.addEventListener('click', handlePricingClick);
    }
  }
}

function setupPlatformLinks() {
  const platformLinks = document.querySelectorAll('.platform-link');

  platformLinks.forEach(link => {
      link.addEventListener('click', (event) => {
          event.preventDefault();

          const platform = link.getAttribute('data-platform');
          if (!platform) {
              console.error('Platform not found in the data attribute');
              return;
          }

          const newUrl = `/browse/${platform}/flows`;
          window.location.href = newUrl;
      });
  });
}

function setupLogoClick(session, subscription) {
  const logoElement = document.getElementById('sidebarLogo');
  if (logoElement) {
    logoElement.addEventListener('click', (event) => {
      event.preventDefault();
      if (session && subscription && subscription.is_current) {
        window.location.href = '/browse/ios/flows/all';
      } else {
        window.location.href = '/';
      }
    });
  } else {
    console.error('Logo element not found');
  }
}

function updateNavLinks(currentPage, isSelected) {
  const links = document.querySelectorAll('nav a[id^="link-"]');
  links.forEach(link => {
    const pageId = link.id.replace('link-', '');
    const selected = isSelected(pageId);
    updateNavLink(pageId, selected);
  });
}

function updateNavLink(pageId, selected) {
  const linkElement = document.getElementById(`link-${pageId}`);
  const iconElement = document.getElementById(`icon-${pageId}`);

  if (linkElement) {
    if (selected) {
      linkElement.classList.add('bg-neutral-900', 'text-white', 'hover:bg-neutral-800');
      linkElement.classList.remove('hover:bg-neutral-200');
    } else {
      linkElement.classList.remove('bg-neutral-900', 'text-white', 'hover:bg-neutral-800');
      linkElement.classList.add('hover:bg-neutral-200');
    }
  }

  if (iconElement) {
    if (selected) {
      iconElement.classList.add('filter', 'invert');
    } else {
      iconElement.classList.remove('filter', 'invert');
    }
  }
}

function getPlatformFromUrl() {
  const pathParts = window.location.pathname.split('/');
  const validPlatforms = ['ios', 'android', 'web', 'watchos'];

  if (pathParts.length > 2 && pathParts[1] === 'browse' && validPlatforms.includes(pathParts[2])) {
    return pathParts[2];
  }
  return null;
}

function setupEventListeners() {
  const userProfile = document.getElementById('userProfile');
  const profileDropdown = document.getElementById('profileDropdown');

  if (userProfile && profileDropdown) {
    userProfile.addEventListener('click', toggleProfileDropdown);
    document.addEventListener('click', closeProfileDropdownOnClickOutside);
  }
}

function toggleProfileDropdown(event) {
  event.stopPropagation();
  const profileDropdown = document.getElementById('profileDropdown');
  profileDropdown.classList.toggle('hidden');
}

function closeProfileDropdownOnClickOutside(event) {
  const userProfile = document.getElementById('userProfile');
  const profileDropdown = document.getElementById('profileDropdown');

  if (userProfile && profileDropdown) {
    if (!userProfile.contains(event.target) && !profileDropdown.contains(event.target)) {
      profileDropdown.classList.add('hidden');
    }
  }
}

document.addEventListener('DOMContentLoaded', () => {
  const currentPlatform = getPlatformFromUrl() || 'home'; // Assuming 'home' as default
  updateNavLinks(currentPlatform, (page) => {
    if (page === 'jobs') {
      return currentPlatform === 'jobs' || currentPlatform === 'post-job';
    }
    if (page === 'apps') {
      return currentPlatform === 'apps';
    }
    return currentPlatform === page;
  });
});